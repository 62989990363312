(function (Common) {
    (function (WebApi) {

        var _version = "v1.0";

        WebApi.getWebApiUrl = function () {
            return "/api/data/" + _version + "/";
        };

        WebApi.getActionUrl = function (options) {
            var url = WebApi.getWebApiUrl() + options.type;
            if (options.id) {
                url += '/' + options.id;
            }
            if (options.actionName) {
                url += '/' + options.actionName;
            }
            return url;
        };

        ///Get Localization labels
        ///name: object name
        WebApi.getLocalization = function (type) {
            try {
                Common.Utility.parameterValidator("string", type, true);
                return new Promise(function (resolve, reject) {
                    $.ajax({
                        url: WebApi.getWebApiUrl() + "localization/" + type,
                        method: 'GET',
                        success: function (result) {
                            resolve(result);
                        },
                        error: function (result) {
                            reject(result.responseJSON.message);
                        }
                    });
                });
            } catch (e) {}
        };

        ///Retrieve multiple records
        ///options
        ///entity type
        ///
        WebApi.retrieveMultiple = function (options) {
            try {
                Common.Utility.parameterValidator("object", options, true);
                Common.Utility.parameterValidator("string", options.type, true);
                Common.Utility.parameterValidator("object", options.params, false);
                return new Promise(function (resolve, reject) {
                    $.ajax({
                        url: WebApi.getWebApiUrl() + options.type,
                        method: 'GET',
                        async: true,
                        data: options.params,
                        success: function (result) {
                            resolve(result);
                        },
                        error: function (result) {
                            reject(result.responseJSON.message);
                        }
                    });
                });
            } catch (e) {}
        };

        ///Create record
        ///options
        ///entity type
        ///
        WebApi.createRecord = function (options) {
            try {
                Common.Utility.parameterValidator("object", options, true);
                Common.Utility.parameterValidator("string", options.type, true);
                Common.Utility.parameterValidator("object", options.data, true);
                return new Promise(function (resolve, reject) {
                    $.ajax({
                        url: WebApi.getWebApiUrl() + options.type,
                        method: 'POST',
                        async: true,
                        data: options.data,
                        success: function (result) {
                            resolve(result);
                        },
                        error: function (result) {
                            reject(result.responseJSON.message);
                        }
                    });
                });
            } catch (e) {}
        };

        ///Execute an action
        ///options
        ///entity type
        ///action Name
        ///data
        WebApi.executeAction = function (options) {
            try {
                Common.Utility.parameterValidator("object", options, true);
                Common.Utility.parameterValidator("string", options.type, true);
                Common.Utility.parameterValidator("string", options.id, false);
                Common.Utility.parameterValidator("string", options.actionName, false);
                return new Promise(function (resolve, reject) {
                    $.ajax({
                        url: WebApi.getActionUrl(options),
                        method: 'POST',
                        async: true,
                        data: options.data,
                        success: function (result) {
                            resolve(result);
                        },
                        error: function (result) {
                            reject(result.responseJSON.message);
                        }
                    });
                });
            } catch (e) {}
        };

    })(Common.WebApi = Common.WebApi || {});
})(window.Common = window.Common || {});