(function (Common) {
    (function (Utility) {

        Utility.isNullOrUndefined = function (value) {
            if (_.isNull(value) || _.isUndefined(value)) {
                return true;
            } else {
                return false;
            }
        };
        Utility.isNullOrEmpty = function (value) {
            if (_.isNull(value) || _.isUndefined(value) || _.isEmpty(value)) {
                return true;
            } else {
                return false;
            }
        };

        Utility.parameterValidator = function (type, parameter, required) {
            if (this.isNullOrUndefined(required)) {
                required = false;
            }
            if (this.isNullOrUndefined(parameter)) {
                if (required) {
                    throw new Error("Parameter Error");
                } else {
                    return;
                }
            }
            switch (type) {
                case "string":
                    if (!_.isString(parameter) || parameter.length === 0) {
                        throw new Error("Parameter Error");
                    }
                    break;
                case "number":
                    if (!_.isNumber(parameter)) {
                        throw new Error("Parameter Error");
                    }
                    break;
                case "array":
                    if (!_.isArray(parameter)) {
                        throw new Error("Parameter Error");
                    }
                    break;
                case "object":
                    if (!_.isObject(parameter)) {
                        throw new Error("Parameter Error");
                    }
                    break;
                default:
                    throw new Error("Parameter Error");
            }
        };

        Utility.getCurrentEntityId = function () {
            return $("#id").val();
        };

        Utility.formType = function () {
            return $("#form_type").val();
        };

        Utility.getCurrentEntityType = function () {
            return $("#entity_type").val();
        };

        Utility.getCloseRoute = function () {
            return $("#close_route").val();
        };

        Utility.getCreateParameters = function () {
            return $("#create_parameters").val();
        };

        Utility.getBaseUrl = function () {
            return $("#base_url").val();
        };

        Utility.showSuccessMessage = function (message) {
            toastr.success(message);
        };

        Utility.showErrorMessage = function (message) {
            toastr.error(message);
        };

    })(Common.Utility = Common.Utility || {});
})(window.Common = window.Common || {});