$(document).ready(function () {
    Common.Constants.getDialogLabels();
    if(Common.Constants.App.entityType)
        Common.Constants.getEntityLabels(Common.Constants.App.entityType);
});

(function (Common) {
    (function (Constants) {
        Constants.Page = {
            FormType: {
                index: "index",
                create: "create",
                show: "show",
                edit: "edit",
                custom: "custom",
                setting: "setting",
                profile: "profile",
            }
        };

        Constants.Button = {
            linkNewId: "#link_new",
            buttonSaveId: "#button_save",
            buttonDeleteId: "#button_delete",
            linkEditId: "#link_edit",
            buttonUpdateId: "#button_update",
            linkCloseId: "#link_close",
            containerId: "#container",
            formDeleteId: "#form_delete",
            linkShowQrsId: "#link_show_qrs",
            buttonChangePasswordId: "#button_change_password",
        };

        Constants.App = {
            baseUrl: Common.Utility.getBaseUrl(),
            entityType: Common.Utility.getCurrentEntityType(),
            entityId: Common.Utility.getCurrentEntityId(),
            formType: Common.Utility.formType(),
            closeRoute: Common.Utility.getCloseRoute(),
            createParameters: Common.Utility.getCreateParameters(),
        };

        Constants.getDialogLabels = function () {
            Common.WebApi.getLocalization("dialog")
                .then(function (result) {
                    Constants.Dialog = result;
                })
                .catch(function (e) {
                    toastr.error(e);
                });
        };

        Constants.getEntityLabels = function (entityType) {
            return Common.WebApi.getLocalization(entityType)
                .then(function(result){
                    Constants.Entity = result;
                })
                .catch(function (e) {
                    toastr.error(e);
                });
        };

    })(Common.Constants = Common.Constants || {});
})(window.Common = window.Common || {});