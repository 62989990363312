(function (Common) {
    (function (ToolbarApi) {
        ToolbarApi.Constants = {
            ButtonType: {
                button: 'button',
                link: 'link',
                submit: 'submit',
            },
            toolbarContainerId: '#toolbar_container',
            buttonNewId: "#button_new",
            buttonSaveId: "#button_save",
            buttonDeleteId: "#button_delete",
            buttonEditId: "#button_edit",
            buttonUpdateId: "#button_update",
            buttonCloseId: "#button_close",
            containerId: "#container",
            formDeleteId: "#form_delete",
            baseUrl: Common.Utility.getBaseUrl(),
            entityType: Common.Utility.getCurrentEntityType(),
            entityId: Common.Utility.getCurrentEntityId(),
            formType: Common.Utility.formType(),
            closeRoute: Common.Utility.getCloseRoute(),
            createParameters: Common.Utility.getCreateParameters(),
        };

        var linkNew, linkEdit, buttonSave, buttonDelete, buttonUpdate, formDelete,
            tableIndexId;

        ToolbarApi.init = function () {
            linkNew = $(ToolbarApi.Constants.buttonNewId);
            linkEdit = $(ToolbarApi.Constants.buttonEditId);
            buttonSave = $(ToolbarApi.Constants.buttonSaveId);
            buttonDelete = $(ToolbarApi.Constants.buttonDeleteId);
            buttonUpdate = $(ToolbarApi.Constants.buttonUpdateId);
            linkClose = $(ToolbarApi.Constants.buttonCloseId);
            formDelete = $(ToolbarApi.Constants.formDeleteId);

            linkClose.attr("href", ToolbarApi.Constants.baseUrl + ToolbarApi.Constants.closeRoute);

            switch (ToolbarApi.Constants.formType) {
                case Common.Constants.Page.FormType.index:
                    ToolbarApi.initIndex();
                    break;
                case Common.Constants.Page.FormType.create:
                    ToolbarApi.initCreate();
                    break;
                case Common.Constants.Page.FormType.show:
                    ToolbarApi.initShow();
                    break;
                case Common.Constants.Page.FormType.edit:
                    ToolbarApi.initEdit();
                    break;
                default:
                    break;

            }
        };

        ToolbarApi.initIndex = function () {
            linkNew.attr("href", ToolbarApi.Constants.baseUrl + ToolbarApi.Constants.entityType + '/create' + ToolbarApi.Constants.createParameters);
            linkEdit.attr("href", ToolbarApi.Constants.baseUrl + ToolbarApi.Constants.entityType + '/' + ToolbarApi.Constants.entityId + '/edit');
            linkNew.removeClass("d-none");
            tableIndexId = $("table:first")[0].id;
            if (!Common.Utility.isNullOrUndefined(tableIndexId)) {
                tableIndexId = `#${tableIndexId}`;
                ToolbarApi.showOnSelectRow();
                ToolbarApi.confirmBulkDelete();
            }
        };

        ToolbarApi.initCreate = function () {
            linkNew.attr("href", ToolbarApi.Constants.baseUrl + ToolbarApi.Constants.entityType + '/create' + ToolbarApi.Constants.createParameters);
            linkNew.removeClass("d-none");
            buttonSave.removeClass("d-none");
        };

        ToolbarApi.initShow = function () {
            linkNew.attr("href", ToolbarApi.Constants.baseUrl + ToolbarApi.Constants.entityType + '/create' + ToolbarApi.Constants.createParameters);
            linkNew.removeClass("d-none");
            linkEdit.attr("href", ToolbarApi.Constants.baseUrl + ToolbarApi.Constants.entityType + '/' + ToolbarApi.Constants.entityId + '/edit');
            linkEdit.removeClass("d-none");
            buttonDelete.removeClass("d-none");
            formDelete.attr("action", ToolbarApi.Constants.baseUrl + ToolbarApi.Constants.entityType + '/' + ToolbarApi.Constants.entityId);
            ToolbarApi.confirmDelete();
        };

        ToolbarApi.initEdit = function () {
            linkNew.attr("href", ToolbarApi.Constants.baseUrl + ToolbarApi.Constants.entityType + '/create' + ToolbarApi.Constants.createParameters);
            linkNew.removeClass("d-none");
            buttonUpdate.removeClass("d-none");
            buttonDelete.removeClass("d-none");
            formDelete.attr("action", ToolbarApi.Constants.baseUrl + ToolbarApi.Constants.entityType + '/' + ToolbarApi.Constants.entityId);
            ToolbarApi.confirmDelete();
        };

        ToolbarApi.hideButtons = function (buttons) {
            Common.Utility.parameterValidator("array", buttons, true);
            buttons.forEach((id, index) => {
                $(id).addClass("d-none");
            });
        };

        ToolbarApi.showButtons = function (buttons) {
            Common.Utility.parameterValidator("array", buttons, true);
            buttons.forEach((id, index) => {
                $(id).removeClass("d-none");
            });
        };

        ToolbarApi.confirmDelete = function () {
            try {
                $(ToolbarApi.Constants.buttonDeleteId).click(function () {
                    $.confirm({
                        title: Common.Constants.Dialog.title_delete,
                        content: Common.Constants.Dialog.content_delete,
                        buttons: {
                            delete: {
                                text: Common.Constants.Dialog.button_delete,
                                action: function () {
                                    Common.Utility.showLoading(ToolbarApi.Constants.containerId, Common.Constants.Dialog.label_deleting);
                                    $(ToolbarApi.Constants.formDeleteId).submit();
                                },
                            },
                            cancel: {
                                text: Common.Constants.Dialog.button_cancel,
                                action: function () {}
                            }
                        }
                    });
                });
            } catch (e) {
                Common.Utility.hideLoading(ToolbarApi.Constants.ContainerId);
                //Common.ErrorHandler.Log(e);
            }
        };

        ToolbarApi.showOnSelectRow = function () {
            try {

                if (!Common.Utility.isNullOrUndefined(tableIndexId)) {
                    $(tableIndexId).DataTable().on('select', function (e, dt, type, indexes) {
                            if (dt.rows({
                                    selected: true
                                }).count() == 1) {
                                $(ToolbarApi.Constants.buttonDeleteId).removeClass("d-none");
                                $(".btn-group").first().addClass('radius-left').end().last().addClass('radius-right');
                            }
                        })
                        .on('deselect', function (e, dt, type, indexes) {
                            if (dt.rows({
                                    selected: true
                                }).count() == 0) {
                                $(ToolbarApi.Constants.buttonDeleteId).addClass("d-none");
                                $(".btn-group").first().addClass('radius-left').end().last().addClass('radius-right');
                            }
                        });
                }

            } catch (e) {
                //Common.ErrorHandler.Log(e);
            }
        };

        ToolbarApi.confirmBulkDelete = function () {
            try {
                $(ToolbarApi.Constants.buttonDeleteId).click(function () {
                    $.confirm({
                        title: Common.Constants.Dialog.title_delete,
                        content: Common.Constants.Dialog.content_deletes,
                        buttons: {
                            delete: {
                                text: Common.Constants.Dialog.button_delete,
                                action: function () {
                                    ToolbarApi.bulkDelete();
                                },
                            },
                            cancel: {
                                text: Common.Constants.Dialog.button_cancel,
                                action: function () {}
                            }
                        }
                    });
                });
            } catch (e) {
                //Common.ErrorHandler.Log(e);
            }
        };

        ToolbarApi.bulkDelete = function () {
            try {
                let entityType = Common.Utility.getCurrentEntityType();
                Common.Utility.showLoading(ToolbarApi.Constants.containerId, Common.Constants.Dialog.label_deleting);
                var rows = $(tableIndexId).DataTable().rows('.selected').data();
                var entityIds = [];
                $.each(rows, function (index, value) {
                    entityIds.push(value.id);
                });
                var data = {
                    ids: entityIds.join(",")
                };
                var options = {
                    type: entityType,
                    data: data
                };
                Common.WebApi.DeleteRecord(options)
                    .then(function (message) {
                        $(tableIndexId).DataTable().rows('.selected').remove().draw(false);
                        $(ToolbarApi.Constants.buttonDeleteId).addClass("d-none");
                        toastr.success(message);
                    })
                    .catch(function (e) {
                        toastr.error(e);
                    })
                    .finally(function (e) {
                        Common.Utility.hideLoading(ToolbarApi.Constants.containerId);
                    });
            } catch (e) {

            }
        };

        ToolbarApi.insertButtons = function (buttons) {
            Common.Utility.parameterValidator("object", buttons, true);
            buttons.forEach(function (button) {
                switch (button.type) {
                    case ToolbarApi.Constants.ButtonType.button:
                        ToolbarApi.initCustomButton(button);
                        break;
                    case ToolbarApi.Constants.ButtonType.link:
                        ToolbarApi.initCustomLinkButton(button);
                        break;
                    case ToolbarApi.Constants.ButtonType.submit:
                        ToolbarApi.initCustomSubmitButton(button);
                        break;
                    default:
                        console.log('unknown button type');
                }
            });
        };

        ToolbarApi.initCustomButton = function (button) {
            var toolbarContainer = $(ToolbarApi.Constants.toolbarContainerId);
            var htmlButton = `
            <button 
            id="${button.id}"  
            type="button" 
            form="${button.form}" 
            class="btn btn-outline-dark ${button.class}" ${button.attr}>
            <i class="${button.icon}"></i>
            ${button.displayName}
            </button>
            `;
            toolbarContainer.append(htmlButton);
        };

        ToolbarApi.initCustomSubmitButton = function (button) {
            var toolbarContainer = $(ToolbarApi.Constants.toolbarContainerId);
            var htmlButton = `
            <button 
            id="${button.id}"  
            type="submit" 
            form="${button.form}" 
            class="btn btn-outline-dark ${button.class}">
            <i class="${button.icon}"></i>
            ${button.displayName}
            </button>
            `;
            toolbarContainer.append(htmlButton);
        };

        ToolbarApi.initCustomLinkButton = function (button) {

        };

    })(Common.ToolbarApi = Common.ToolbarApi || {});
})(window.Common = window.Common || {});