(function (Common) {
    (function (TableApi) {

        TableApi.initTable = function (options) {
            Common.Utility.parameterValidator("object", options, true);
            Common.Utility.parameterValidator("string", options.entityType, true);
            Common.Utility.parameterValidator("string", options.tableId, true);
            Common.Utility.parameterValidator("array", options.columns, true);
            Common.Utility.parameterValidator("array", options.columnDefs, false);

            $(options.tableId).dataTable({
                ajax: `${Common.WebApi.getWebApiUrl()}${options.entityType}`,
                columns: options.columns,
                columnDefs: options.columnDefs,
            });
        };

        TableApi.getSelectedRows = function (tableId) {
            var rows = $(tableId).DataTable().rows('.selected').data();
            return rows;
        };

        TableApi.refreshTable = function (tableId) {
            $(tableId).DataTable().clear().draw();
            $(tableId).DataTable().ajax.reload();
            $(tableId).DataTable().columns.adjust().draw();
        };

        TableApi.showOnSelectRow = function (tableId, elementId) {
            try {
                $(tableId).DataTable().on('select', function (e, dt, type, indexes) {
                        if (dt.rows({
                                selected: true
                            }).count() == 1) {
                            $(elementId).removeClass("d-none");
                            $(".btn-group").first().addClass('radius-left').end().last().addClass('radius-right');
                        }
                    })
                    .on('deselect', function (e, dt, type, indexes) {
                        if (dt.rows({
                                selected: true
                            }).count() == 0) {
                            $(elementId).addClass("d-none");
                            $(".btn-group").first().addClass('radius-left').end().last().addClass('radius-right');
                        }
                    });
            } catch (e) {
                Common.ErrorHandler.Log(e);
            }
        };

    })(Common.TableApi = Common.TableApi || {});
})(window.Common = window.Common || {});